







































































import { Component, Vue, Ref } from "vue-property-decorator";
import {Udf} from '@/classes/HtmlToUdf';
import 'jodit/build/jodit.min.css'
import { JoditEditor } from 'jodit-vue'

@Component({
    components: {
        JoditEditor
    }
})
export default class GenerateHTMLtoUDF extends Vue {
    specialFieldsStatus:Boolean=false;
    listSearch : string = "";
    selectedItem : string = "";
    content: string = '<p></p>';
    config:any={
        i18n: 'tr',
        buttons: [
            'bold',
            'underline',
            'italic',
            '|',
            'fontsize',
            'ul',
            'ol',
            '|',
            'align', 
            'undo', 
            'redo', 
            '|',
            'table',
            'print',
        ],
    }
    customButtons:any= [
        {
            name: 'insertVariable',
            iconURL: 'https://www.flaticon.com/svg/static/icons/svg/1/1300.svg',
            exec: (editor:any) => {
                this.specialFieldFunc();
            }
        }
    ]
    list: Array<any> =[
        {
            name:"Tarih",
            html:"{mahkeme_tarihi}",
            value:"07/11/2020"
        },
        {
            name:"Müvekkilin Adı",
            html:"{müvekkilin_adi}",
            value:"Yasin"
        },
        {
            name:"Müvekkilin Soyadı",
            html:"{müvekkilin_soyadi}",
            value:"Özen"
        },
        {
            name:"Adliye",
            html:"{adliye_adi}",
            value:"Bayraklı Adliyesi"
        },
        {
            name:"Adliye Adresi",
            html:"{adliye_adresi}",
            value:"Mustafa Kemal Paşa Mah. 6*8/1 sok. No:**  İzmir / İzmir İzmir / İzmir / İzmir Torbalı Torbalı Torbalı / Test / Test Bayraklı / İzmir Tes"
        }
    ];



    get zonesFilter(){
        const search = this.listSearch.toLowerCase().trim();

        if (!search) return this.list;

        return this.list.filter(c => c.name.toLowerCase().indexOf(search) > -1);
    }

    specialFieldFunc(){
        if(this.specialFieldsStatus){
            this.specialFieldsStatus=false
        }else{
            this.specialFieldsStatus=true
        }
        
    }

    filterBlur(){
        let elem = <HTMLTextAreaElement>document.getElementsByClassName("jodit-wysiwyg")[0]; 
        this.selectAll(elem);
    }

    appendText(item:any){
        this.selectedItem=item.name

        let elem = <HTMLInputElement>document.getElementsByClassName("jodit-wysiwyg")[0];
        console.log(document.getElementsByClassName("jodit-wysiwyg")[0].childNodes)
        this.selectAll(elem);

        window.document.execCommand("insertText", false, item.html);
        this.specialFieldsStatus=false
    }

    selectAll(el:any) { 
        console.log(el);
        //el.select();
        el.focus(); 
         
    };

    generateUDF(){
        new Udf(this.content,this.list).generate();
    }

}
