






import {Component, Vue} from "vue-property-decorator";
import {HesapNumarasiEntity} from "@/entity/HesapNumarasiEntity";
import dilekceler from "@/components/dilekceler/editor.vue";
@Component({
    components: {
        dilekceler,
    },
})
export default class Anasayfa extends Vue {
    print:boolean=false;
    onCreateSuccess(val: any) {
        
    }
};
