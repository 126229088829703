import JSZip, {forEach} from 'jszip';
import * as FileSaver from 'file-saver';


interface fieldItem {
    name: string,
    html: string,
    value: string
}

interface elementItem {
    key: string,
    value: string
}


export class Udf {
    html: string;
    fields: Array<fieldItem>;
    lengthSum: number = 0;

    constructor(theHtml: string, theFields: Array<fieldItem>) {
        this.html = theHtml;
        this.fields = theFields;
    }

    generate() {
        new Promise((resolve, reject) => {
            let cleanHTML = this.htmlCleaner(this.html)
            let XMLTemplateTag = this.XMLTemplateTagCreated(cleanHTML)
            if (XMLTemplateTag) {
                resolve(XMLTemplateTag);
            } else {
                reject("XMLTemplateTag Çalışmadı!");
            }
        }).then((templateTag: any) => {
            this.prepareToDownload(templateTag)
        }).catch((err) => {
            console.log(err)
        })
    }

    isHTML(html: any) {
        var a = document.createElement('div');
        a.innerHTML = html;

        for (var c = a.childNodes, i = c.length; i--;) {
            if (c[i].nodeType == 1) return true;
        }

        return false;
    }

    htmlCleaner(html: any) {
        html = this.decodeHTMLEntities(html)

        if (this.isHTML(html) == false) {
            let p = document.createElement("p");
            p.innerHTML = html;
            html = p.outerHTML
        }

        let div = document.createElement("div");
        div.innerHTML = html;

        let divChildNodes = Array.prototype.slice.call(div.childNodes)

        if (divChildNodes.length == 1) {
            div = document.createElement("div");
            let savedIndex: number[] = [];
            divChildNodes.forEach((element, index: number) => {
                let sub = element
                while (sub.childNodes && sub.childNodes.length == 1) {
                    if (sub.tagName == 'OL' || sub.tagName == 'UL' || sub.tagName == 'P' || sub.tagName == 'TABLE') {
                        div.appendChild(sub)
                        savedIndex.push(index)
                    }
                    if (sub.childNodes[0]) {
                        sub = sub.childNodes[0]
                    }
                }
            });
            div.append(divChildNodes[0])
        } else if (divChildNodes.length > 1) {
            div = document.createElement("div");
            let savedIndex: number[] = [];
            divChildNodes.forEach((element, index: number) => {
                let sub = element
                while (sub.childNodes && sub.childNodes.length > 0) {
                    if (sub.tagName == 'OL' || sub.tagName == 'UL' || sub.tagName == 'P' || sub.tagName == 'TABLE') {
                        div.appendChild(sub)
                        savedIndex.push(index)
                    }
                    if (sub.childNodes[0]) {
                        sub = sub.childNodes[0]
                    }
                }
                if (savedIndex.includes(index) == false) {
                    div.appendChild(sub)
                }
            });
        }
        div.innerHTML = this.variableToString(div.innerHTML, this.fields)
        html = div.innerHTML
        return html
    }

    XMLTemplateTagCreated(html: any) {
        //content tag'i oluşturdum.
        let mainContent = this.createElement('content');

        //HTML TO STRING
        let mainContentValue = '<![CDATA[' + this.parseHTMLToString(html) + ']]';
        mainContent.append(mainContentValue);

        //properties tag'i oluşturdum.
        let properties = this.createElement('properties')

        //pageFormat tag'i oluşturdum.
        const pageFormatArray = [
            {key: 'mediaSizeName', value: '1'},
            {key: 'leftMargin', value: '70.875'},
            {key: 'rightMargin', value: '70.875'},
            {key: 'topMargin', value: '70.875'},
            {key: 'bottomMargin', value: '70.875'},
            {key: 'paperOrientation', value: '1'},
            {key: 'headerFOffset', value: '20.0'},
            {key: 'footerFOffset', value: '20.0'},
        ]

        let pageFormat = this.createElement('pageFormat', pageFormatArray)
        properties.appendChild(pageFormat);

        //styles tag'i oluşturdum.
        let styles = this.createElement('styles');

        //style tag'i oluşturdum.
        let styleArray = [
            {key: 'name', value: 'default'},
            {key: 'description', value: 'Geçerli'},
            {key: 'family', value: 'Dialog'},
            {key: 'size', value: '12'},
            {key: 'bold', value: 'false'},
            {key: 'italic', value: 'false'},
            {
                key: 'FONT_ATTRIBUTE_KEY',
                value: 'javax.swing.plaf.FontUIResource[family=Dialog,name=Dialog,style=plain,size=12]'
            },
            {key: 'foreground', value: '-13421773'},
        ]

        let style = this.createElement('style', styleArray);
        styles.appendChild(style);

        //style2 tag'i oluşturdum.
        let styleArray2 = [
            {key: 'name', value: 'hvl-default'},
            {key: 'family', value: 'Times New Roman'},
            {key: 'size', value: '12'},
            {key: 'description', value: 'Gövde'}
        ]

        let style2 = this.createElement('style', styleArray2);
        styles.appendChild(style2);

        //template tag'i oluşturdum.
        var xmlDoc = document.implementation.createDocument(null, 'root', null);
        let templateTag = xmlDoc.createElement('template');
        templateTag.setAttribute("format_id", '1.7');
        templateTag.appendChild(mainContent);
        templateTag.appendChild(this.createElementsTag(html))
        templateTag.appendChild(properties);
        templateTag.appendChild(styles);

        return templateTag
    }

    prepareToDownload(templateTag: any) {
        var xmls = '<?xml version="1.0" encoding="UTF-8" ?>' + '\n' + new XMLSerializer().serializeToString(templateTag) + '\n';
        //var xmlDocx = new DOMParser().parseFromString(xmls, "application/xml");

        let newReplaceXML = this.replaceText(xmls);

        //console.log(newReplaceXML)

        this.downloadToFile(newReplaceXML, "Dilekçe Adı.udf", "application/xml");
    }

    downloadToFile(content: string, filename: string, contentType: string) {
        const a = document.createElement('a');
        const file = new Blob([content], {type: contentType});
        var zip = new JSZip();
        zip.file("content.xml", file);
        zip.generateAsync({type: "blob"})
            .then(function (contents) {
                FileSaver.saveAs(contents, filename);
            });
    }

    createElementsTag(html: any) {

        let elements = this.createElement('elements', [{key: 'resolver', value: 'hvl-default'}]);

        let properies = {
            elements,
            tag: {}
        }

        //Fake div oluşturup editörün içindeki elemanları array'e dönüştürdüm.
        let div = document.createElement("div");

        div.innerHTML = html;

        let divChildNodes = Array.prototype.slice.call(div.childNodes)

        divChildNodes.forEach((tag, index) => {

            properies.tag = tag

            //Özel Taglar burda yakalanır.
            properies = this.createListTags(properies)
            properies = this.createTableTags(properies)
            properies = this.createOtherTags(properies)

        });

        return properies.elements
    }

    createListTags(properties: any) {
        let {tag, elements} = properties
        if (tag.tagName && (tag.tagName.toLowerCase() == 'ol' || tag.tagName.toLowerCase() == 'ul')) {
            //Ol veya Ul altındaki Li.
            if (tag.children.length > 0) {
                let liList = {
                    level: 1,
                    indent: 25
                }

                tag.children.forEach((li: any, liIndex: number) => {
                    //paragraph tag'i oluşturdum.
                    let paragraphArray = [];
                    if (tag.tagName.toLowerCase() == 'ol') {
                        paragraphArray = [
                            {key: 'Numbered', value: 'true'},
                            {key: 'NumberType', value: 'NUMBER_TYPE_NUMBER_DOT'},
                            {key: 'ListId', value: '2'},
                        ]
                    } else {
                        paragraphArray = [
                            {key: 'Bulleted', value: 'true'},
                            {key: 'BulletType', value: 'BULLET_TYPE_ELLIPSE'},
                            {key: 'ListId', value: '4'},
                        ]
                    }

                    if (tag.attributes[0]) {
                        let liTabNumber = tag.attributes[0].value.split("ql-indent-")[1];
                        liList.level = Number(liTabNumber) + 1
                        liList.indent = (Number(liTabNumber) + 1) * 25

                        //Li'lerde özellik varsa burda parçalanır.
                        paragraphArray = this.parseAttributes(tag, paragraphArray)

                    } else if (tag.attributes[0] == undefined) {
                        liList = {
                            level: 1,
                            indent: 25
                        }
                    }

                    paragraphArray.push(
                        {key: 'ListLevel', value: liList.level.toString()},
                        {key: 'LeftIndent', value: liList.indent.toFixed(1).toString()},
                    )
                    let paragraph = this.createElement('paragraph', paragraphArray);
                    li.childNodes.forEach((liChild: any, liIndex: number) => {
                        let contentArray: elementItem[] | undefined = [];

                        //Yazının uzunluğu toplanır.
                        let length = 0;
                        if (li.childNodes.length - 1 == liIndex) {
                            length = liChild.textContent.length + 1
                        } else {
                            length = liChild.textContent.length
                        }
                        //İlk startOffset index daima 0'dır. Sonrakiler toplanarak devam eder.
                        contentArray.push({key: 'startOffset', value: this.lengthSum.toString()})
                        contentArray.push({key: 'length', value: length.toString()})

                        //Li tag'ın içinde taglar varsa burda parçalanır.
                        contentArray = this.parseHtmlTags(liChild.outerHTML, contentArray)

                        this.lengthSum += length


                        let content = this.createElement('content', contentArray);
                        paragraph.appendChild(content);

                    })

                    elements.appendChild(paragraph);
                })

            }
        }
        return {
            elements,
            tag
        }
    }

    createTableTags(properties: any) {
        let {tag, elements} = properties

        if (tag.tagName && (tag.tagName.toLowerCase() == 'table')) {

            const tBody = tag.children[0].children

            if (tBody && tBody.length > 0) {

                let rowArray: any = [];
                let tableColumnCount = 0;
                let rowValues: any = [];
                tBody.forEach((tr: any, trIndex: number) => {
                    tableColumnCount = tr.children.length;

                    let rowAttributeArray = [
                        {key: 'rowName', value: `row${(Number(trIndex) + 1)}`},
                        {key: 'rowType', value: 'dataRow'},
                        {key: 'height_min', value: '0.5'},
                        {key: 'border', value: 'borderNone'},
                        {key: 'borderWidth', value: '0.5'},
                    ]
                    if (trIndex == 1) {
                        rowAttributeArray.push({key: 'height', value: '0.0'})
                    }

                    let row = this.createElement('row', rowAttributeArray);

                    if (tr.childNodes.length > 0) {

                        tr.childNodes.forEach((td: any, tdIndex: number) => {
                            let paragraphArray: elementItem[] | undefined = [];
                            let contentArray: elementItem[] | undefined = [];
                            let cellAttributeArray = [
                                {key: 'border', value: 'borderNone'},
                                {key: 'borderWidth', value: '0.5'},
                            ]

                            let cell = this.createElement('cell', cellAttributeArray);

                            paragraphArray.push({key: 'LeftIndent', value: '3.0'});
                            paragraphArray.push({key: 'RightIndent', value: '1.0'});
                            paragraphArray.push({key: 'Alignment', value: '0'});

                            //Taglarda özellik varsa burda parçalanır.
                            paragraphArray = this.parseAttributes(td, paragraphArray)

                            let paragraph = this.createElement('paragraph', paragraphArray);

                            let length = 0;
                            //Yazının uzunluğu toplanır.
                            length = td.textContent.length + 1

                            //İlk startOffset index daima 0'dır. Sonrakiler toplanarak devam eder.
                            contentArray.push({key: 'startOffset', value: this.lengthSum.toString()})
                            contentArray.push({key: 'length', value: length.toString()})
                            contentArray.push({key: 'LeftIndent', value: '3.0'})
                            contentArray.push({key: 'RightIndent', value: '1.0'});
                            contentArray.push({key: 'Alignment', value: '0'});

                            this.lengthSum += length

                            //Html tag'ın içinde taglar varsa burda parçalanır.
                            contentArray = this.parseHtmlTags(td.outerHTML, contentArray)

                            let content = this.createElement('content', contentArray);

                            paragraph.appendChild(content);
                            cell.appendChild(paragraph);
                            row.appendChild(cell);
                        })

                        tr.children.forEach((td: any, tdIndex: number) => {
                            //Değişkenleri asıl değeriyle düzenleme
                            let replaceText = this.variableToString(td.textContent, this.fields)
                            //Yazının uzunluğu toplanır.
                            let length = replaceText.length + 1
                            if (rowValues[tdIndex]) {
                                if (length > rowValues[tdIndex]) {
                                    rowValues[tdIndex] = length
                                }
                            } else {
                                rowValues[tdIndex] = length
                            }
                        })
                    }
                    rowArray.push(row);
                })

                const tableArray = [
                    {key: 'tableName', value: 'Sabit'},
                    {key: 'columnCount', value: tableColumnCount.toString()}, // Değişkenle değişecek
                    {key: 'columnSpans', value: this.columnSpansCalc(rowValues)}, // Değişkenle değişecek
                    {key: 'border', value: 'borderNone'},
                    {key: 'borderSpec', value: '31'},
                    {key: 'borderColor', value: '-16777216'},
                    {key: 'borderStyle', value: 'borderStyle-plain'},
                    {key: 'borderWidth', value: '1.0'},
                    {key: 'rowSpans', value: '400,400'}, // Değişkenle değişecek
                ]

                let table = this.createElement('table', tableArray);
                rowArray.forEach((element: any) => {
                    table.appendChild(element);
                });

                elements.appendChild(table);
            }
        }
        return {
            elements,
            tag
        }
    }

    createOtherTags(properties: any) {
        let {tag, elements} = properties
        if (tag.tagName && (tag.tagName.toLowerCase() == 'table' || tag.tagName.toLowerCase() == 'ul' || tag.tagName.toLowerCase() == 'ol')) {
            return properties
        }


        if (tag.childNodes.length > 0) {
            let paragraphArray: elementItem[] | undefined = [];

            //Taglarda özellik varsa burda parçalanır.
            paragraphArray = this.parseAttributes(tag, paragraphArray)
            let paragraph = this.createElement('paragraph', paragraphArray);
            tag.childNodes.forEach((tagChild: any, index: number) => {

                let contentArray: any = []

                contentArray = this.parseHtmlTags(tagChild.outerHTML, contentArray)
                let length = 0;
                if (tag.childNodes.length - 1 == index) {
                    length = tagChild.textContent.length + 1
                } else {
                    length = tagChild.textContent.length
                }

                contentArray.push(
                    {key: 'startOffset', value: this.lengthSum.toString()},
                    {key: 'length', value: length.toString()},
                )

                this.lengthSum += length


                contentArray = this.parseAttributes(tagChild, contentArray)
                let content = this.createElement('content', contentArray);

                paragraph.appendChild(content);

            });
            elements.appendChild(paragraph);
        } else {
            let paragraph = this.createElement('paragraph');
            let contentArray: any = []
            contentArray.push(
                {key: 'startOffset', value: this.lengthSum.toString()},
                {key: 'length', value: "1"},
            )
            this.lengthSum += 1
            let content = this.createElement('content', contentArray);
            paragraph.appendChild(content);
            elements.appendChild(paragraph);
        }


        return {
            elements,
            tag
        }
    }

    columnSpansCalc(tableColumnArray: any) {
        let columnSpans: string = '';
        if (tableColumnArray && tableColumnArray.length > 0) {
            let columnSpansSum: number = tableColumnArray.reduce((a: number, b: number) => {
                return a + b;
            }, 0);

            let maxColumn = {
                val: -1,
                index: 0,
            }

            tableColumnArray.forEach((col: number, index: number) => {
                tableColumnArray.forEach((subCol: number, subIndex: number) => {
                    let resultSub = Number((Number((subCol / columnSpansSum).toFixed(2)) * 100).toFixed(0))
                    if (maxColumn.val < resultSub) {
                        maxColumn.val = resultSub
                        maxColumn.index = subIndex
                    }
                })
                let result = Number((Number((col / columnSpansSum).toFixed(2)) * 100).toFixed(0))
                if (result < 10) {
                    result = result + 5;
                    maxColumn.val = maxColumn.val - 5;
                }
                tableColumnArray[maxColumn.index] = maxColumn.val
                if (tableColumnArray[index + 1]) {
                    columnSpans += String(result) + ','
                } else {
                    columnSpans += String(result)
                }
            })
        }
        return columnSpans
    }

    parseHTMLToString(html: string) {
        //Fake div oluşturup editörün içindeki elemanları array'e dönüştürdüm.
        let div = document.createElement("div");
        div.innerHTML = html;
        let stringContent = ""
        if (div.children.length > 0) {
            let divChildren = Array.prototype.slice.call(div.childNodes)
            divChildren.forEach(element => {
                let sub = element
                if (sub.tagName == 'UL' || sub.tagName == 'OL') {
                    let subChildNodes = sub.childNodes
                    subChildNodes.forEach((subElement: any) => {
                        stringContent += subElement.innerText + '\n'
                    })
                } else if (sub.tagName == 'TABLE') {
                    const tBody = sub.children[0].children
                    tBody.forEach((tr: any, trIndex: number) => {
                        if (tr.childNodes.length > 0) {

                            tr.childNodes.forEach((td: any, tdIndex: number) => {
                                stringContent += td.textContent + '\n'
                            })
                        }
                    })
                } else {
                    if (sub.innerText == undefined) {
                        let p = document.createElement("p");
                        p.innerHTML = sub.textContent;
                        stringContent += p.innerText.trim() + '\n'
                    } else {
                        stringContent += sub.innerText + '\n'
                    }

                }
            });
        }

        return this.variableToString(stringContent, this.fields)
    }

    decodeHTMLEntities(text: string) {
        var entities = [
            ['amp', '&'],
            ['apos', '\''],
            ['#x27', '\''],
            ['#x2F', '/'],
            ['#39', '\''],
            ['#47', '/'],
            ['lt', '<'],
            ['gt', '>'],
            ['nbsp', ' '],
            ['quot', '"']
        ];

        for (var i = 0, max = entities.length; i < max; ++i)
            text = text.replace(new RegExp('&' + entities[i][0] + ';', 'g'), entities[i][1]);

        return text;
    }

    createElement(tag: string, params?: Array<elementItem>) {
        let element = document.createElement(tag)
        if (params && params.length > 0) {
            params.forEach((attribute: any) => {
                element.setAttribute(attribute.key, attribute.value)
            })
        }
        return element
    }

    variableToString(text: string, fieldArray: Array<fieldItem>) {

        //Değişkenleri asıl değeriyle düzenleme
        let replaceText = text.valueOf()
        fieldArray.find((c: any) => {
            if (replaceText.indexOf(c.html) != -1) {


                replaceText = replaceText.replace(new RegExp(c.html, "g") , c.value);
            }
        })

        return replaceText
    }

    parseHtmlTags(element: any, array: Array<elementItem>) {
        if (array) {
            if (element) {
                if (element.length > 0 && element.indexOf('<b') != -1) {
                    array.push({key: 'bold', value: 'true'})
                }

                if (element.length > 0 && element.indexOf('<strong') != -1) {
                    array.push({key: 'bold', value: 'true'})
                }

                if (element.length > 0 && element.indexOf('<i') != -1) {
                    array.push({key: 'italic', value: 'true'})
                }

                if (element.length > 0 && element.indexOf('<em') != -1) {
                    array.push({key: 'italic', value: 'true'})
                }

                if (element.length > 0 && element.indexOf('<u') != -1) {
                    array.push({key: 'underline', value: 'true'})
                }
            }
        }
        return array
    }

    parseAttributes(element: any, array: Array<elementItem>) {
        if (array) {
            if (element.attributes && element.attributes.length > 0) {
                element.attributes.forEach((attributes: any, index: number) => {
                    if (attributes.name.trim().toString() == 'style') {
                        let attributesName = attributes.value.trim().toString().split(':')[0]
                        let attributesValue = attributes.value.trim().toString().split(':')[1]
                        switch (attributesValue.trim()) {
                            case 'center;':
                                array.push({key: 'Alignment', value: '1'})
                                break;
                            case 'right;':
                                array.push({key: 'Alignment', value: '2'})
                                break;
                            case 'justify;':
                                array.push({key: 'Alignment', value: '3'})
                                break;
                            case 'left;':
                                array.push({key: 'Alignment', value: '0'})
                                break;
                            default:
                                array.push({key: 'Alignment', value: '0'})
                                break;
                        }

                        if(attributesName=='font-size'){
                            array.push({key: 'size', value: attributesValue.split('px')[0].trim()})
                        }

                    } else if (attributes.name.trim().toString() == 'size') {
                        switch (attributes.value.trim().toString()) {
                            case '1':
                                array.push({key: 'size', value: '12'})
                                break;
                            case '2':
                                array.push({key: 'size', value: '13'})
                                break;
                            case '3':
                                array.push({key: 'size', value: '16'})
                                break;
                            case '4':
                                array.push({key: 'size', value: '18'})
                                break;
                            case '5':
                                array.push({key: 'size', value: '24'})
                                break;
                            case '6':
                                array.push({key: 'size', value: '32'})
                                break;
                            case '7':
                                array.push({key: 'size', value: '48'})
                                break;
                            default:
                                break;
                        }
                    }

                })
            }
        }
        return array
    }

    replaceText(replaceText: string, array?: Array<elementItem>) {
        let replaceXMLArray = [
            {key: '></content>', value: ' />'},
            {key: ' xmlns="http://www.w3.org/1999/xhtml"', value: ''},
            {key: '></style>', value: ' />'},
            {key: 'startoffset', value: 'startOffset'},
            {
                key: '<pageformat mediasizename="1" leftmargin="70.875" rightmargin="70.875" topmargin="70.875" bottommargin="70.875" paperorientation="1" headerfoffset="20.0" footerfoffset="20.0"></pageformat>',
                value: '<pageFormat mediaSizeName="1" leftMargin="70.875" rightMargin="70.875" topMargin="70.875" bottomMargin="70.875" paperOrientation="1" headerFOffset="20.0" footerFOffset="20.0" />'
            },
            {key: '&lt;', value: '<'},
            {key: 'font_attribute_key', value: 'FONT_ATTRIBUTE_KEY'},
            {key: ']]', value: ']]>'},
            {key: 'alignment', value: 'Alignment'},
            {key: 'numbered', value: 'Numbered'},
            {key: 'numbertype', value: 'NumberType'},
            {key: 'number_type_number_dot', value: 'NUMBER_TYPE_NUMBER_DOT'},
            {key: 'listlevel', value: 'ListLevel'},
            {key: 'listid', value: 'ListId'},
            {key: 'leftindent', value: 'LeftIndent'},
            {key: 'rightindent', value: 'RightIndent'},
            {key: 'bulleted', value: 'Bulleted'},
            {key: 'bullettype', value: 'BulletType'},
            {key: 'bullet_type_ellipse', value: 'BULLET_TYPE_ELLIPSE'},
            {key: 'tablename', value: 'tableName'},
            {key: 'bordernone', value: 'borderNone'},
            {key: 'sabit', value: 'Sabit'},
            {key: 'columncount', value: 'columnCount'},
            {key: 'columnspans', value: 'columnSpans'},
            {key: 'borderspec', value: 'borderSpec'},
            {key: 'bordercolor', value: 'borderColor'},
            {key: 'borderstyle', value: 'borderStyle'},
            {key: 'borderstyle-plain', value: 'borderStyle-plain'},
            {key: 'borderwidth', value: 'borderWidth'},
            {key: 'rowspans', value: 'rowSpans'},
            {key: 'rowname', value: 'rowName'},
            {key: 'rowtype', value: 'rowType'},
            {key: 'datarow', value: 'dataRow'},
        ]
        if (array && array.length > 0) {
            array.forEach((element: any) => {
                replaceXMLArray.push(element)
            })
        }
        replaceXMLArray.forEach((element: any) => {
            replaceText = replaceText.replace( new RegExp(element.key, "g")  , element.value);
        })
        return replaceText
    }

}
